import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import useDataPolicy from "../../../hooks/useDataPolicy"
import "./data-policy.scss"

const DataPolicy = () => {
  const { politicaDeDatos } = useDataPolicy()
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => <p>{next}</p>,
      [INLINES.HYPERLINK]: (node, next) => (
        <a target="_blank" rel="noreferrer" href={node.data.uri}>
          {next}
        </a>
      ),
    },
  }

  return (
    <div className="f-data-policy">
      {documentToReactComponents(politicaDeDatos.json, options)}
    </div>
  )
}

export default DataPolicy
