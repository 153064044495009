import React, { useEffect, useState } from "react"
import FormPerosnalData from "../../../components/molecules/form-personal-data/form-personal-data"
import NavigatorSteps from "../../../components/atoms/navigator-steps/navigator-steps"
import { sessionStorage } from "../../../shared/utils/storage"
import homeLink from "../../../shared/utils/siteUrl"
import { useForm } from "react-hook-form"
import { isAdult, isLife } from "../../../shared/constants/dateConstants"
import useDataPolicy from "../../../shared/hooks/useDataPolicy"
import "./first-step-content.scss"

const FirstStepContent = props => {
  const { title, setModalActive, isMobile } = props

  const [defaultData] = useState(sessionStorage.getItem("infoRegister"))
  const { checksDePoliticas } = useDataPolicy()

  /*Register for array form*/
  const {
    register,
    handleSubmit,
    formState,
    errors,
    watch,
    trigger,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name: defaultData?.name || "",
      lastname: defaultData?.lastname || "",
      gender: defaultData?.gender || "",
      day: defaultData?.day || "",
      month: defaultData?.month || "",
      year: defaultData?.year || "",
      tycEmail:
        defaultData?.tycEmail || defaultData?.tycEmail === 1 ? true : false,
      dataPolicies:
        defaultData?.dataPolicies || defaultData?.dataPolicies === 1
          ? true
          : false,
    },
  })

  const onSubmit = data => {
    if (!isAdult(data.day, data.month, data.year)) {
      setError("day", {
        type: "manual",
        message: "Debes ser mayor de edad",
      })
      return false
    } else if (!isLife(data.day, data.month, data.year)) {
      setError("day", {
        type: "manual",
        message: "Debes ser menor a 100 años",
      })
      return false
    }

    let infoRegister = sessionStorage.getItem("infoRegister")
    infoRegister.name = data.name?.trim()?.replace("  ", " ")
    infoRegister.lastname = data.lastname?.trim()?.replace("  ", "")
    infoRegister.gender = data.gender
    infoRegister.day = data.day
    infoRegister.month = data.month
    infoRegister.year = data.year

    checksDePoliticas.forEach(({ idCategoria }) => {
      infoRegister[idCategoria] = data[idCategoria] ? 1 : 0
    })

    sessionStorage.setItem("infoRegister", infoRegister)

    window.location.href = homeLink + "registro/paso-2"
  }

  useEffect(() => {
    if (defaultData && !defaultData.email && !defaultData.cellphone) {
      window.location.href = homeLink + "registro/"
    }
  }, [])

  return (
    <div className="f-first-step-content">
      <h2 className="f-title--purple f-form-register-title">{title}</h2>

      <NavigatorSteps totalSteps={4} activeStep={1} />

      <h2 className="f-title--purple f-register-title">Datos personales</h2>

      <FormPerosnalData
        register={register}
        formState={formState}
        errors={errors}
        clearErrors={clearErrors}
        setError={setError}
        trigger={trigger}
        watch={watch}
        onSubmit={handleSubmit(onSubmit)}
        setValue={setValue}
        defaultData={defaultData}
        checksDePoliticas={checksDePoliticas}
      />
    </div>
  )
}

export default FirstStepContent
