import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CustomInputText from "../../../shared/components/atoms/inputText/InputText"
import CustomInputTextDate from "../../../shared/components/atoms/inputTextDate/inputTextDate"
import CustomCheckboxNative from "../../../shared/components/atoms/custom-checkbox-native/custom-checkbox-native"
import Button from "../../../shared/components/atoms/button/button"
import homeLink from "../../../shared/utils/siteUrl"
import {
  DaysOfMonth,
  formatDateService,
} from "../../../shared/constants/dateConstants"
import {
  NAME_PATTERN,
  YEAR_MAX_LENGTH,
  YEAR_MAX,
  YEAR_MIN,
  DATE_MAX_LENGTH,
  DATE_MIN,
  NUMBER_PATTERN,
} from "../../../shared/constants/appConstants"
import DataPolicy from "../../../shared/components/atoms/data-policy/data-policy"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "./form-personal-data.scss"

const FormPersonalData = props => {
  const {
    register,
    errors,
    watch,
    trigger,
    formState,
    onSubmit,
    backAction,
    className,
    defaultData,
    setValue,
    checksDePoliticas
  } = props

  React.useEffect(() => {
    if (defaultData?.birthday) {
      let date = formatDateService(defaultData.birthday)
      setValue("day", date.day)
      setValue("month", date.month)
      setValue("year", date.year)
      trigger(["day", "month", "year"])
    }
  }, [])

  React.useEffect(() => {
    watch("month") !== "" && trigger("day")
  }, [watch("month"), watch("year"), watch("year")])


  return (
    <form
      className={
        "f-register-form f-rows-container " + (className ? className : "")
      }
      onSubmit={onSubmit}
      autoComplete="off"
    >
      <div className="f-row">
        <div className="f-container-input">
          <CustomInputText
            fullWidth={true}
            label="Nombre(s)"
            id="name"
            required={true}
            inputProps={{
              name: "name",
              maxLength: 100,
              placeholder: "Angela Maria",
            }}
            inputRef={register({
              required: "Este campo es requerido",
              maxLength: {
                value: 100,
                message: "Se requieren máximo 100 caracteres",
              },
              pattern: {
                value: NAME_PATTERN,
                message: "El nombre es inválido",
              },
            })}
            error={errors.name ? true : false}
            errorText={
              errors.name
                ? errors.name.message
                  ? errors.name.message
                  : "Campo inválido"
                : ""
            }
          />
        </div>

        <div className="f-container-input">
          <CustomInputText
            fullWidth={true}
            label="Apellidos"
            id="lastname"
            required={true}
            inputProps={{
              name: "lastname",
              maxLength: 100,
              placeholder: "Ciro Ordoñez",
            }}
            inputRef={register({
              required: "Este campo es requerido",
              maxLength: {
                value: 100,
                message: "Se requieren máximo 100 caracteres",
              },
              pattern: {
                value: NAME_PATTERN,
                message: "El apellido es inválido",
              },
            })}
            error={errors.lastname ? true : false}
            errorText={
              errors.lastname
                ? errors.lastname.message
                  ? errors.lastname.message
                  : "Campo inválido"
                : ""
            }
          />
        </div>
      </div>

      <div className="f-row">
        <div className="f-container-input">
          <div className="f-select">
            <select
              name="gender"
              id="gender"
              ref={register({ required: "Este campo es requerido" })}
            >
              <option defaultValue disabled value="">
                Género
              </option>
              <option value="FEMENINO">Femenino</option>
              <option value="MASCULINO">Masculino</option>
              <option value="INDEFINIDO">Prefiero no responder</option>
            </select>
            <label htmlFor="gender" className="f-label-error">
              {errors.gender
                ? errors.gender.message
                  ? errors.gender.message
                  : "Campo inválido"
                : ""}
            </label>
          </div>
        </div>

        <div className="f-container-input f-container-input--date">
          <CustomInputTextDate
            fullWidth={true}
            label="Fecha de nacimiento"
            id="date"
            required={true}
            inputRefDay={register({
              required: "El campo es requerido",
              maxLength: DATE_MAX_LENGTH,
              min: { value: DATE_MIN, message: "Día inválido" },
              max: {
                value: DaysOfMonth(watch("month"), watch("year")) === false ? 31 : DaysOfMonth(watch("month"), watch("year")),
                message: "Día inválido",
              },
              pattern: { value: NUMBER_PATTERN, message: "Campo inválido" },
            })}
            inputRefMonth={register({
              required: "El campo es requerido",
              maxLength: DATE_MAX_LENGTH,
              max: { value: 12, message: "Mes inválido" },
              min: { value: DATE_MIN, message: "Mes inválido" },
              pattern: { value: NUMBER_PATTERN, message: "Campo inválido" },
            })}
            inputRefYear={register({
              required: "El campo es requerido",
              maxLength: YEAR_MAX_LENGTH,
              max: { value: YEAR_MAX, message: "Año inválido" },
              min: { value: YEAR_MIN, message: "Año inválido" },
              pattern: { value: NUMBER_PATTERN, message: "Campo inválido" },
            })}
            error={errors.day || errors.month || errors.year ? true : false}
            errorText={
              errors.day
                ? errors.day.message
                : errors.month
                ? errors.month.message
                : errors.year
                ? errors.year.message
                : "Campo inválido"
            }
          />
        </div>
      </div>

      {checksDePoliticas.map(
        ({ idCategoria, descripcionRecomendadosCategorias }, index) => {
          return (
            <div className="f-row" key={index}>
              <div className="f-container-input last f-check-terms-container">
                <CustomCheckboxNative
                  className="f-check-terms"
                  label={documentToReactComponents(
                    descripcionRecomendadosCategorias.json
                  )}
                  name={idCategoria}
                  inputRef={register({
                    required: "Este campo es requerido",
                  })}
                  error={errors[idCategoria] ? true : false}
                  errorText={
                    errors[idCategoria]
                      ? errors[idCategoria].message
                        ? errors[idCategoria].message
                        : "Campo inválido"
                      : ""
                  }
                />
              </div>
            </div>
          )
        }
      )}

      <DataPolicy />
      <div className="f-buttons-container">
        <Button
          className="f-btn-purple f-button-prev"
          // typeButton="button"
          title="Atrás"
          href="registro/"
          onClick={backAction}
        />

        <Button
          className="f-primary-purple f-button-next"
          typeButton="button"
          type="submit"
          title="Siguiente"
          disabled={!formState.isValid}
        />
      </div>
    </form>
  )
}

export default FormPersonalData
