import { graphql, useStaticQuery } from "gatsby"

export default function useDataPolicy() {
  const result = useStaticQuery(
    graphql`
      query DATA_POLICY {
        dataPolicy: allContentfulPetysRegistro {
          nodes {
            node_locale
            checksDePoliticas {
              idCategoria
              descripcionRecomendadosCategorias {
                json
              }
            }
            politicaDeDatos {
              json
            }
          }
        }
      }
    `
  )

  const resultQuery = result.dataPolicy.nodes.filter(
    data => data.node_locale === process.env.LOCALE
  )[0]

  return {
    politicaDeDatos: resultQuery.politicaDeDatos,
    checksDePoliticas: resultQuery.checksDePoliticas,
  }
}
