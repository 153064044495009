import React from "react"
import {
  FormControl,
  InputLabel,
  InputBase,
  FormHelperText,
} from "@material-ui/core"
import { withStyles, createStyles } from "@material-ui/core/styles"
import {
  latoRegular,
  purple,
  contrastMedium,
} from "../../../styles/variables.scss"

const CustomLabel = withStyles(theme => ({
  root: {
    color: "#5D5D5D",
    fontFamily: latoRegular,
    fontSize: 16,
    marginLeft: 15,
    marginBottom: 3,
    position: "relative",
    bottom: 0,
    left: 0,
    textAlign: "left",
  },
}))(InputLabel)

const InputText = withStyles(theme => ({
  root: {
    color: "#A5A5A5",
    width: "27%",
    maxWidth: "90px",
    "&:nth-child(3)": {
      width: "40%",
      maxWidth: 115,
    },
  },
  input: {
    borderRadius: 23,
    // border: 1px solid #E8E8E8;
    position: "relative",
    backgroundColor: theme.palette.common.white,
    color: purple,
    border: `1px solid ${contrastMedium}`,
    textAlign: "center",
    fontSize: 16,
    minHeight: 45,
    boxSizing: "border-box",
    padding: "10px 20px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    margingBottom: 20,
    marginTop: 0,
    // Use the system font instead of the default Roboto font.
    fontFamily: [latoRegular, "sans-serif"].join(","),
    placeholcer: {
      color: '"red',
    },
    "&::placeholder": {
      color: "#A5A5A5",
    },
  },
  disabled: {
    color: "#A5A5A5",
  },
  error: {
    "& input": {
      borderColor: "red",
    },
  },
}))(InputBase)

const styles = createStyles({
  formHelperText: {
    position: "absolute",
    left: 15,
    bottom: -20,
    // marginLeft: 15,
  },
  InputsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  span: {
    color: "#E35400",
  },
})

const CustomInputTextDate = props => {
  const { id, label, error, fullWidth, errorText, required, onChange } = props

  const inputPropsDay = props.inputPropsDay
    ? props.inputPropsDay
    : {
        name: "day",
        maxLength: 2,
        placeholder: "28",
      }
  const inputPropsMoth = props.inputPropsMoth
    ? props.inputPropsMoth
    : {
        name: "month",
        maxLength: 2,
        placeholder: "02",
      }
  const inputPropsYear = props.inputPropsYear
    ? props.inputPropsYear
    : {
        name: "year",
        maxLength: 4,
        placeholder: "1993",
      }

  return (
    <FormControl fullWidth={fullWidth} style={styles.FormControl} onChange={onChange}>
      <CustomLabel shrink htmlFor={id} focused={false}>
        {label} {label && required && <span style={styles.span}>*</span>}
      </CustomLabel>
      <div style={styles.InputsContainer}>
        <InputText
          error={error}
          id={id}
          inputProps={inputPropsDay}
          inputRef={props.inputRefDay}
          type="Number"
        />
        <InputText
          error={error}
          id={id}
          inputProps={inputPropsMoth}
          inputRef={props.inputRefMonth}
          type="Number"
        />
        <InputText
          error={error}
          id={id}
          inputProps={inputPropsYear}
          inputRef={props.inputRefYear}
          type="Number"
        />
      </div>
      {error && errorText && (
        <FormHelperText error={error} style={styles.formHelperText}>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default CustomInputTextDate
